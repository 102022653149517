<template>
  <div class="menu-advertising" v-loading.fullscreen="loading">
    <div
      v-if="resultData.type === 2"
      id="videoId"
      class="mb-20"
    />
  </div>
</template>

<script>
import { AliVideoPlayer } from '@jsh/helper/utils'
import { CloudUser } from '../../../apis'
export default {
  name: 'Advertising',
  props: {
    id: {
      type: String,
      required: false,
      default: ''
    }
  },
  data () {
    return {
      resultData: {},
      loading: false
    }
  },
  watch: {
    id () {
      if (this.id) {
        this.getDate()
      }
    }
  },
  methods: {
    /**
       * type 头部展示类型：1：图片；2：视频；3.网页
       * 1：图片路径image_url
       * 2：视频地址：routePcUrl，封面图片：imageUrl
       * 3：待补充
       *  目前逻辑，是一个菜单对应一个广告
       */
    getDate () {
      this.loading = true
      this.$http.get(CloudUser.searchSysMenuPortalConfigByMenuId, { menuId: this.id }).then(res => {
        if (res.data.success) {
          this.resultData = res.data.data[0] || {}
          if (this.resultData.type === 2) {
            // 视频格式
            this.$nextTick(() => {
              const videoId = new AliVideoPlayer({
                id: 'videoId',
                source: this.resultData.routePcUrl, // m3u8播放地址
                cover: this.resultData.imageUrl, // 封面图地址
                height: '400px'
              })
              console.log(videoId)
            })
          }
        }
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  created () {
    this.getDate()
  }
}
</script>

<style scoped lang="scss">
</style>
