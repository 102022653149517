<template>
  <div class="menu">
    <advertising :id="$route.query.id"/>
    <div v-for="(item, index) in thirdMenu.children" :key="index">
      <div
        class="menu_four"
        :style="{marginTop: index > 0 ? '14px' : '0px'}"
        v-if="item.children && item.children.length > 0"
      >
        <img
          class="menu_four_menu-arrow"
          src="@/assets/images/menu-arrow.png"
          alt=""
        >
        <span class="fs-16 color-999999 ml-5">{{ item.name }}</span>
        <img
          v-if="item.promotionUrl"
          class="menu_four_hot"
          :src="item.promotionUrl"
          alt=""
        >
      </div>
      <el-row :gutter="30" v-if="item.children && item.children.length > 0">
        <el-col
          :xs="12"
          :sm="8"
          :md="8"
          :lg="8"
          :xl="6"
          v-for="(itemSon, indexSon) in item.children"
          :key="indexSon"
        >
          <div class="menu_five mb-6" @click="toPage(itemSon)">
            <div class="d-flex align-items-center justify-content-between">
              <img
                class="menu_five_img"
                :src="itemSon.iconUrl ? itemSon.iconUrl : menuDefaultFiveImg"
                alt=""
              >
              <div class="ml-10 menu_five_right">
                <div class="menu_five_right_top">
                  <div class="d-flex align-items-center">
                    <span
                      v-ellipsis.tooltip="1"
                      style="max-width:80%;"
                      class="d-inline-block fs-16 fw-600 color-303133 "
                    >
                      {{ itemSon.name }}
                    </span>
                    <img
                      class="menu_five_right_top_hot"
                      v-if="itemSon.promotionUrl"
                      :src="itemSon.promotionUrl"
                      alt=""
                    >
                  </div>
                </div>
                <div
                  class="fs-14 color-909399"
                  v-ellipsis.tooltip="1"
                  style="width: 80%"
                >
                  {{ itemSon.desc }}
                </div>
              </div>
              <el-button
                type="primary"
                size="mini"
                plain
                class="button"
              >
                进入
              </el-button>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import menuDefaultFive from '@/assets/images/menu-default-five.png'
import storeMixin from '../../mixins/store'
import advertising from '@/views/menu/components/advertising.vue'
import { CloudUser } from '../../apis'
export default {
  mixins: [storeMixin],
  components: {
    advertising
  },
  /* =========================属性============================ */
  data () {
    return {
      thirdMenu: {},
      menuDefaultFiveImg: menuDefaultFive
    }
  },

  watch: {
    '$route' () {
      this.init()
    }
  },
  methods: {
    /* =========================方法============================ */
    toPage (menu) {
      this.$http.post(CloudUser.updateTotalCount, {
        menuId: menu.id
      }).then(res => {
        if (res.data.success) {
          if (menu.ifNewPage === 1) {
            window.open(menu.pcUrl)
          } else {
            this.$router.push(menu.pcUrl)
          }
        }
      })
    },
    init () {
      let menuList = localStorage.getItem('menuList')
      let breadcrumbList = []
      menuList && (menuList = JSON.parse(menuList) || [])
      if (menuList && menuList.length > 0) {
        // 遍历数据，获取对应的三级菜单
        this.thirdMenu = {}
        menuList.forEach(menu => {
          if (menu.children && menu.children.length > 0) {
            menu.children.forEach(twoMenu => {
              if (twoMenu.children && twoMenu.children.length > 0) {
                if (!this.thirdMenu.id) {
                  this.thirdMenu = twoMenu.children.find(e => e.id.toString() === this.$route.query.id) || {}
                  breadcrumbList = [{ name: menu.name }, { name: twoMenu.name }, { name: this.thirdMenu.name }]
                }
              }
            })
          }
        })
      }
      this.store.commit('changeBreadcrumbList', breadcrumbList)
      window.document.title = this.thirdMenu.name || '易理货'
    }
  },

  created () {
    this.init()
  }
}

</script>

<style scoped lang="scss">
  .menu{
    padding:20px;
    .color-999999{
      color: #999999;
    }
    .color-000000{
      color: #000000;
    }
    .color-909399{
      color: #909399;
    }
    .color-303133{
      color: #303133;
    }
    &_four{
      padding-left:4px;
      display: flex;
      align-items:center;
      &_menu-arrow{
        width:16px;
        height:16px;
      }
      &_hot{
        margin-left: 2px;
        width:36px;
        height:14px;
      }
    }
    &_five{
      margin-top: 10px;
      padding:12px 24px 13px 23px;
      width: 100%;
      height: 80px;
      background: #FFFFFF;
      border: 2px solid #EBEEF5;
      cursor:pointer;
      &_img{
        flex: 0 0 48px;
        height:48px;
      }
      &_right{
        width:calc(100% - 48px - 56px);
        &_top{
          &_hot{
            margin-left: 2px;
            margin-top:-3px;
            width:36px;
            height:14px;
          }
        }
      }
      &:hover{
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
      }
      .button{
        flex:0 0 56px;
      }
    }
  }
</style>
